import React from "react"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import FreeTrial from '../components/free-trial'
import TwoColumn from "../components/two-column"
import ComparisonTable from "../components/comparison-table"
import Testimonial from '../components/testimonial'
import SwiperTabs from '../components/swiper-tabs'
import PlanAction from '../components/plan-action'
import CustomerJourney from '../components/customer-journey'
// import GetStarted from '../components/get-started'
import {
  bannerContent, 
  comparisonTableContent,
  trialContent,
  planActionContent,
  customerJourneyContent,
  developersContent,
  // getStartedContent
} from "../lib/shoptalk-content"
import { swiperTabsContent } from "../lib/swiper-tabs-content"
// import { testimonialContent } from "../lib/testimonial-content"

const Shoptalk = () => (
  <Layout isLandingPage isHeaderTransparent>
    <SEO 
      title="Billsby | Subscription billing software for recurring payments" 
      description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software." 
      url="https://www.billsby.com/shoptalk"
    />

     <TwoColumn content={bannerContent}/>
     <SwiperTabs 
      tabs={swiperTabsContent} 
      title="If you can dream it, we can bill it." 
      description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans." 
     />
     <PlanAction content={planActionContent} />
     <CustomerJourney content={customerJourneyContent} />
     <ComparisonTable content={comparisonTableContent}/>
     <TwoColumn content={developersContent}/>
     {/* <GetStarted content={getStartedContent} /> */}
     <Testimonial />
     <FreeTrial content={trialContent}/>
  </Layout>
)

export default Shoptalk
